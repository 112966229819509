import React, { useEffect, useState } from 'react'
import { fetchStateList } from 'services/CommonService'
import CustomFormSelect from './Select'

const StatesDropDown = ({
  name,
  values,
  errors,
  touched,
  infoText,
  asterisk,
  countryId,
  setStateList,
  handleChange,
}) => {
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleFetchStateList = async (id) => {
    setIsLoading(true)
    const { status, data } = await fetchStateList({ country_id: id })
    setIsLoading(false)

    if (status) {
      setList(data.data || [])
      setStateList && setStateList(data.data)
    }
  }

  useEffect(() => {
    if (countryId) {
      handleFetchStateList(countryId)
    }
  }, [countryId])

  return (
    <>
      <CustomFormSelect
        name={name}
        label="State"
        values={values}
        errors={errors}
        touched={touched}
        optionsArr={list}
        infoText={infoText}
        isLoading={isLoading}
        placeholder={'Select'}
        asterisk={asterisk || false}
        handleChange={handleChange && handleChange}
      />
    </>
  )
}

export default StatesDropDown
