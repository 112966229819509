export const APP_NAME = 'Payment Router'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export const ORDER_STATUS = {
  PENDING: {
    code: 'PENDING',
    color: 'yellow-500 ',
    title: 'PENDING',
  },
  APPROVED: {
    code: 'APPROVED',
    color: 'green-500',
    title: 'APPROVED',
  },
  REJECTED: {
    code: 'REJECTED',
    color: 'red-500',
    title: 'REJECTED',
  },
  CAPTURED: {
    code: 'CAPTURED',
    color: 'bg-blue-500',
    title: 'CAPTURED',
  },

  AUTHORISED: {
    code: 'AUTHORISED',
    color: 'bg-green-500 text-white',
    title: 'AUTHORIZED',
  },
  REFUNDED: {
    code: 'REFUNDED',
    color: 'bg-purple-600 text-white',
    title: 'REFUNDED',
  },
  CHARGED_BACK: {
    code: 'CHARGED_BACK',
    color: 'bg-orange-500 text-white',
    title: 'CHARGED BACK',
  },

  UNKNOWN: {
    code: 'UNKNOWN',
    color: 'bg-red-100 text-red-500',
    title: 'UNKNOWN',
  },
  FAILED: {
    code: 'FAILED',
    color: 'bg-red-400 text-white',
    title: 'FAILED',
  },
  DECLINED: {
    code: 'DECLINED',
    color: 'bg-red-700 text-white',
    title: 'DECLINED',
  },
  REFUND_REQUESTED: {
    code: 'REFUND_REQUESTED',
    color: 'bg-purple-500 text-white',
    title: 'REFUND REQUESTED',
  },
  SENT_FOR_REFUND: {
    code: 'SENT_FOR_REFUND',
    color: 'bg-purple-500 text-white',
    title: 'SENT FOR REFUND',
  },
  REFUSED: {
    code: 'REFUSED',
    color: 'bg-red-700 text-white',
    title: 'REFUSED',
  },
}
