import { Steps } from 'components/ui'
import React from 'react'

const DocuSteps = ({ step = 0, isRejected }) => {
  return (
    <div>
      <Steps current={step} {...(isRejected && { status: 'error' })}>
        <Steps.Item title="Docusign" />
        <Steps.Item title="Entity Verification" />
        <Steps.Item title="Admin Approval" />
      </Steps>
    </div>
  )
}

export default DocuSteps
