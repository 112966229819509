import axios from 'axios'
import appConfig from 'configs/app.config.js'
import { reduxStore } from 'store'
import { handleSignOut } from 'utils/helper'

const parseAxiosError = (err) => {
  if (err.response?.data?.error && err.response?.data?.message) {
    return err.response.data.message
  }
  if (err.response?.data?.error) {
    return err.response.data.error
  }
  if (err.response?.data) {
    // ignore errors containing html view
    if (!err.response.headers['content-type'].startsWith('text/html')) {
      return err.response.data
    }
  }
  return err.message
}

const apiClient = async (url, method, req_data, params, signal) => {
  let data
  let error = null
  let status
  try {
    const token = reduxStore.getState().auth.session.token || 'lol'
    const apiUrl = appConfig.baseUrl

    const resp = await axios({
      baseURL: apiUrl,
      url,
      method,
      data: req_data,
      headers: { authorization: token },
      params,
      signal:signal
    })

    data = resp.data
    status = true
  } catch (err) {
    // console.log(err, 'error')
    if(err?.response?.status==401){
      handleSignOut()
    }
    error = parseAxiosError(err)
    status = false
  }
  return { data, error, status }
}

apiClient.get = (url, params,signal) => {
  return apiClient(url, 'GET', null, params,signal)
}
apiClient.post = (url, data) => {
  return apiClient(url, 'POST', data)
}
apiClient.put = (url, data) => {
  return apiClient(url, 'PUT', data)
}
apiClient.patch = (url, data) => {
  return apiClient(url, 'PATCH', data)
}
apiClient.delete = (url, data) => {
  return apiClient(url, 'DELETE', data)
}

export default apiClient
