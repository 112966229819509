import React, {  useState } from 'react'
import MyButton from 'components/custom-button'

import { HiOutlineLogin } from 'react-icons/hi'
import DocuSteps from './steps'
import DocuHeader from './header'
import apiClient from 'apiClient'
import { useSelector } from 'react-redux'
import Kyc from './kyc'
import AdminApproval from './admin-approval'
import Reject from './reject'
import { APPROVAL_STATUS } from 'constants/roles.constant'
import { openNotification } from 'utils/notificate'

const Docusign = ({ step }) => {

  const [loading, setLoading] = useState(false)
  const state = useSelector((state) => state.auth)
  const { user } = state

  const handleDocuSignCLick = async () => {
    setLoading(true)
    const { data, status,error } = await apiClient.get('/docusign/create', {
      id: user?.ID,
    })
    setLoading(false)
    if (status) {
      let a = document.createElement('a')
      a.href = data.data
      a.target = '_self'
      a.click()
    }
    if(error){
      openNotification('danger',error?.message|| 'Something went wrong')
    }
  }

  const render = (step, user) => {
    if (step == 0) {
      return (
        <MyButton
          size={'md'}
          text={'Docusign'}
          isLoading={loading}
          className={'text-center'}
          icon={<HiOutlineLogin size={22} />}
          handleClick={handleDocuSignCLick}
        />
      )
    }
    if (step === 1) {
      return <Kyc userId={ user?.ID}/>
    }
    if (step === 2) {
      if (user?.ApprovalStatus === APPROVAL_STATUS.PENDING) {
        return <AdminApproval />
      }
      if (user?.ApprovalStatus === APPROVAL_STATUS.REJECTED) {
        return <Reject />
      }
    }
  }

  return (
    <>
     <DocuHeader />
      <div className={`flex items-center justify-center min-h-[100vh] ${step==1? ' mt-20':''}`}>
        <div className="max-w-2xl w-full px-2">
          <DocuSteps
            step={step}
            isRejected={
              user?.ApprovalStatus === APPROVAL_STATUS.REJECTED ?? false
            }
          />
          <div className="flex justify-center mt-10 max-w-2xl mx-auto">{render(step, user)}</div>
        </div>
      </div>
    </>
  )
}

export default Docusign
