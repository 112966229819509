import React from 'react'

const Reject = () => {
  return (
    <div className='text-center'>
        <p className='text-6xl mb-3'>&#128532;</p>
      <h4 className='text-center'>
        We regret to inform you that your account has been rejected. If you have
        any questions, please contact us at <a className='text-blue-500 underline' href="mailto: info@payment-router.com">info@payment-router.com</a>
      </h4>
    </div>
  )
}

export default Reject
