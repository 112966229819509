import React, { useState } from 'react'
import { Avatar, Dropdown } from 'components/ui'
import withHeaderItem from 'utils/hoc/withHeaderItem'
import useAuth from 'utils/hooks/useAuth'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { AiOutlineLogout } from 'react-icons/ai'
import { HiOutlineLockClosed, HiOutlineUser, HiOutlineUserCircle  } from 'react-icons/hi'

import Dialog from 'components/ui/Dialog'
import ChangePassword from 'components/change-password'

export const UserDropdown = ({ className }) => {
  // bind this
  const userInfo = useSelector((state) => state.auth.user)

  const [dialogIsOpen, setIsOpen] = useState(false)

  const { signOut } = useAuth()

  const openDialog = () => {
    setIsOpen(true)
  }

  const onDialogClose = (e) => {
    setIsOpen(false)
  }

  const onDialogOk = (e) => {
    setIsOpen(false)
  }

  const UserAvatar = (
    <div className={classNames(className, 'flex items-center gap-2')}>
      <Avatar size={32} shape="circle" icon={<HiOutlineUser />} />
      <div className="hidden md:block">
        <div className="text-xs capitalize">{userInfo.Role}</div>
        <div className="font-bold capitalize">{`${userInfo.FirstName} ${
          userInfo.LastName ?? ''
        }`}</div>
      </div>
    </div>
  )

  return (
    <div>
      <div className="max-h-96 overflow-y-auto">
        <Dialog
          isOpen={dialogIsOpen}
          onClose={onDialogClose}
          onRequestClose={onDialogClose}
        >
          <ChangePassword onOpen={onDialogOk} onClose={onDialogClose} />
        </Dialog>
      </div>

      <Dropdown
        menuStyle={{ minWidth: 240 }}
        renderTitle={UserAvatar}
        placement="bottom-end"
      >
        <Dropdown.Item variant="header">
          <div className="py-2 px-3 flex items-center gap-2">
            <Avatar shape="circle" icon={<HiOutlineUser />} />
            <div>
              <div className="font-bold text-gray-900 dark:text-gray-100">
                {`${userInfo.FirstName} ${userInfo.LastName ?? ''}`}
              </div>
              <div className="text-xs">{userInfo.Email}</div>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item variant="divider" />
        <Link to={'/profile'}>
          <Dropdown.Item className="gap-2">
            <span className="text-xl opacity-50">
              <HiOutlineUserCircle  />
            </span>
            <span>Profile</span>
          </Dropdown.Item>
        </Link>
        <Dropdown.Item onClick={() => openDialog()} className="gap-2">
          <span className="text-xl opacity-50">
            <HiOutlineLockClosed />
          </span>
          <span>Change Password</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={signOut} eventKey="Sign Out" className="gap-2">
          <span className="text-xl opacity-50">
            <AiOutlineLogout />
          </span>
          <span>Sign Out</span>
        </Dropdown.Item>
      </Dropdown>
    </div>
  )
}

export default withHeaderItem(UserDropdown)
