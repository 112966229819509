import apiClient from 'apiClient.js'

export async function apiVerifySignInOTP(data, isQrOtp) {
  const url = isQrOtp ? '/auth/verify-2fa/sign-in' : '/auth/verify-otp/sign-in'

  const res = await apiClient.post(url, data)

  if (!res.status) {
    return {
      error: res.error,
      status: res.status,
    }
  }

  const user = res.data.data
  const payload = res.data.token
  const userId = user.id

  delete user.id
  user.userId = userId

  const response = {
    user,
    token: payload,
    rememberMe: data.rememberMe || false,
  }

  return {
    code: 200,
    data: response,
    message: 'Success',
    status: true,
  }
}

export async function fetchUserById(id) {
  const url = '/users/details/' + id
  const res = await apiClient.get(url)

  if (!res.status) {
    return {
      error: res.error,
      status: false,
    }
  }

  const user = res.data.data
  const userId = user.id

  delete user.id
  user.userId = userId

  const response = {
    user,
  }

  return {
    data: response,
    message: 'Success',
    status: true,
  }
}

export async function apiSignUp(data) {
  const url = '/auth/sign-up'
  const res = await apiClient.post(url, data)

  if (res.error) {
    throw res.error.message
  }

  return { ...res.data, status: 'ok' }
}

export async function apiSignOut(data) {
  return true
}

export async function apiForgotPassword(body) {
  const url = '/auth/forgot-password'
  const { status, error, data } = await apiClient.post(url, body)

  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}
export async function apiResetPassword(body) {
  const url = '/auth/verify-otp/reset-password'
  const { status, error, data } = await apiClient.post(url, body)

  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}

export const signInService = async (body) => {
  const { status, error, data } = await apiClient.post('/auth/sign-in', body)
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}
export const changePassword = async (body) => {
  const { status, error, data } = await apiClient.patch(
    '/users/change-password',
    body
  )
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}
