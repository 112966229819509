import React, { useEffect, useState } from 'react'
import KycFormStep from './kyc-form'
import { fetchKYCDetail } from 'services/CommonService'

const Kyc = ({ userId }) => {
  const [kycData, setKycData] = useState(null)

  const getKycDetail = async (id) => {
    const { status, data } = await fetchKYCDetail({ id })
    if (status) {
      setKycData({
        control_person: data.data.personal_information,
        company_information: data.data.company_information,
      })
    }
  }

  useEffect(() => {
    if (userId) {
      getKycDetail(userId)
    }
  }, [userId])

  return (
    <>
      <KycFormStep kycData={kycData} userId={userId}/>
    </>
  )
}

export default Kyc
