import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'

export const rootReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
    // ...asyncReducers,
})
// const rootReducer = (asyncReducers) => (state, action) => {
//     return combinedReducer(state, action)
// }

// export default rootReducer
