import apiClient from "apiClient"

export const kycControlPersonService = async (body) => {
    const { status, error, data } = await apiClient.post(
      '/users/kyc/step-one',
      body
    )
    if (status) {
      return { data: data, status: true }
    } else {
      return { error: error, status: false }
    }
  }

export const kycBusinessService = async (body) => {
    const { status, error, data } = await apiClient.post(
      '/users/kyc/step-two',
      body
    )
    if (status) {
      return { data: data, status: true }
    } else {
      return { error: error, status: false }
    }
  }