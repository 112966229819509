import { Button } from 'components/ui'
import React, { Fragment } from 'react'
import './custom-button.css'
import { HiArrowNarrowRight } from 'react-icons/hi'

const MyButton = ({
  icon,
  text,
  size,
  type,
  shape,
  color,
  variant,
  isLoading,
  className,
  handleClick,
}) => {
  const renderIcon = () => {
    if (icon) {
      return icon
    } else if (type == 'submit') {
      return <HiArrowNarrowRight />
    } else {
      return <></>
    }
  }

  return (
    <>
      <Button
        color={color}
        size={size || 'sm'}
        icon={renderIcon()}
        loading={isLoading}
        type={type || 'button'}
        shape={shape || 'round'}
        variant={variant || 'solid'}
        onClick={handleClick && handleClick}
        className={`custom__button_class ${className}`}
      >
        {text}
      </Button>
    </>
  )
}

export default MyButton
