import CustomFormSelect from 'components/my-custom/Select'
import React, { useEffect, useState } from 'react'
import { fetchCountryList } from 'services/CommonService'

// import { useSelector } from 'react-redux'
// import { INSTITUTION } from 'constants/roles.constant'

const EntityCountryDropDown = ({
  name,
  label,
  values,
  errors,
  touched,
  disabled,
  handleChange,
  setCountryList,
}) => {
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleFetchCountry = async () => {
    setIsLoading(true)
    const url = '/dropdowns/countries'
    const { status, data } = await fetchCountryList(url)
    setIsLoading(false)

    if (status) {
      setList(data.data || [])
      setCountryList && setCountryList(data.data || [])
    }
  }

  useEffect(() => {
    handleFetchCountry()
  }, [])

  return (
    <div>
      <CustomFormSelect
        asterisk
        values={values}
        errors={errors}
        touched={touched}
        placeholder={'Select'}
        name={name}
        label={label || 'Country'}
        optionsArr={list}
        isLoading={isLoading}
        handleChange={handleChange && handleChange}
        disabled={disabled}
      />
    </div>
  )
}

export default EntityCountryDropDown
