import { createSlice } from '@reduxjs/toolkit'

export const initialState = JSON.parse(localStorage.getItem('user_info')) || {
  ApprovalStatus: '',
  Email: '',
  FirstName: '',
  ID: '',
  ImageUrl: '',
  IsActive: '',
  IsDocusigned: '',
  IsEmailVerified: '',
  IsKycCompleted: '',
  IsTfaEnabled: '',
  LastName: '',
  Role: '',
  customer_id: null,
  partner_ids: null,
  partners: '',
}

export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    setImageUrlAction: (state, action) => {
      state.ImageUrl = action.payload
    },
    setAuthenticationAction: (state, action) => {
      state.IsTfaEnabled = action.payload
    },
    userLoggedOut: () => initialState,
  },
})

export const { setUser, setAuthenticationAction, setImageUrlAction } =
  userSlice.actions

export default userSlice.reducer
