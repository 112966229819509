const appConfig = {
  apiPrefix: '/api',
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  // baseUrl: 'https://7f9f-74-208-34-144.ngrok-free.app',
  authenticatedEntryPath: '/home',
  adminEntryPath: '/admin/home',
  customerEntryPath: '/core/transactions',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: false,
}

export default appConfig
