import apiClient from 'apiClient'
import ApiService from './ApiService'

export async function apiGetNotificationCount() {
  return {
    count: 0,
  }
}

export async function apiGetNotificationList() {
  return []
}

export async function apiGetSearchResult(data) {
  return ApiService.fetchData({
    url: '/search/query',
    method: 'post',
    data,
  })
}

export const fetchCountryList = async (url, params) => {
  const { status, error, data } = await apiClient.get(
    url,
    params
  )
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}
export const fetchStateList = async (params) => {
  const { status, error, data } = await apiClient.get(
    '/dropdowns/states',
    params
  )
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}

export const fetchKYCDetail = async (params) => {
  const { status, error, data } = await apiClient.get(
    '/users/kyc-details/'+params.id,
  )
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}

export const fetchTypeIDs = async (ids) => {
  const { status, error, data } = await apiClient.get(
    `/dropdowns/document-types?ids=${ids}`,
  )
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}

export const dropdownBulkPayment = async (id) => {
  const { status, error, data } = await apiClient.get(
    `/dropdowns/bulk-payments?partner_id=${id}`,
  )
  if (status) {
    return { data: data, status: true }
  } else {
    return { error: error, status: false }
  }
}
