import React from 'react'
import { FormItem, Input, Tooltip } from 'components/ui'
import { Field } from 'formik'
import { HiOutlineInformationCircle } from 'react-icons/hi'

const Info = ({ info }) => (
  <Tooltip title={info}>
    <HiOutlineInformationCircle className="text-lg ml-1" />
  </Tooltip>
)

const InputFormField = ({
  type,
  name,
  label,
  errors,
  touched,
  infoText,
  asterisk = false,
  placeholder,
  info = '',
  disable = false,
  accept = '',
  dataAos,
}) => {
  return (
    <div data-aos={dataAos || 'fade-up'}>
      <FormItem
        label={label}
        asterisk={asterisk}
        invalid={errors && errors[name] && touched[name]}
        errorMessage={errors && errors[name]}
        {...(info && { extra: <Info info={info} /> })}
      >
        <Field
          type={type || 'text'}
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          component={Input}
          min={0}
          disabled={disable}
          accept={accept}
          {...(type === 'file' && {
            onChange: (e) => console.log(e.target.file),
          })}
        />
        {infoText && <span className='mt-1 block  text-xs' style={{ fontStyle: 'italic' }}>{infoText}</span>}
      </FormItem>
    </div>
  )
}

export default InputFormField
