import { Form, Formik } from 'formik'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import MyButton from 'components/custom-button'
import InputFormField from 'components/my-custom/Input'
import CustomFormSelect from 'components/my-custom/Select'
import { Card, FormContainer, FormItem, Input } from 'components/ui'
import CountryDropDown from 'components/my-custom/country-dropdown'
import StatesDropDown from 'components/my-custom/state-dropdown'
import {
  enquiryTypeOption,
  businessInitialValues,
  businessValidationSchema,
  businessBodyParser,
} from './helper'
import BusinessDocument from './business-document'
import { kycBusinessService } from 'services/KycService'
import { openNotification } from 'utils/notificate'
import EntityCountryDropDown from './country-dropdown-entity'

const BusinessInfo = ({ handleBack, company_information }) => {
  const handleStepTwo = async (values, setSubmitting) => {
    setSubmitting(true)
    const body = await businessBodyParser({
      ...values,
    })

    const { status, data, error } = await kycBusinessService(body)

    if (status) {
      openNotification('success', data?.message || 'Updated successfully')
      setTimeout(() => {
        window.location.reload()
        setSubmitting(false)
      }, 1000)
    }
    if (error) {
      openNotification('danger', error?.message || 'Something went wrong')
      setSubmitting(false)
    }
  }
  return (
    <div className="pb-8">
      <h5 className="mb-4">Entity Verification</h5>
      <Formik
        initialValues={company_information || businessInitialValues}
        validationSchema={businessValidationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleStepTwo(values, setSubmitting)
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => {
          return (
            <Form>
              <FormContainer>
                <Card>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                    <InputFormField
                      asterisk
                      name="legal_name"
                      label="Legal Name"
                      errors={errors}
                      touched={touched}
                      placeholder="Legal Name"
                    />
                    <InputFormField
                      asterisk
                      name="tax_id"
                      label="Tax Id"
                      errors={errors}
                      touched={touched}
                      placeholder="Tax ID"
                    />
                    <InputFormField
                      asterisk
                      name="address"
                      label="Address"
                      errors={errors}
                      touched={touched}
                      placeholder="Address"
                    />
                    <div className="relative z-10">
                      <EntityCountryDropDown
                        values={values}
                        errors={errors}
                        touched={touched}
                        name="country_id"
                      />
                    </div>
                    <div className="relative z-[9]">
                      <StatesDropDown
                        asterisk={true}
                        values={values}
                        errors={errors}
                        touched={touched}
                        name="state_id"
                        countryId={values?.country_id || null}
                      />
                    </div>

                    <InputFormField
                      asterisk
                      name="city"
                      label="City"
                      errors={errors}
                      touched={touched}
                      placeholder="City"
                    />

                    <InputFormField
                      asterisk
                      name="postal_code"
                      label="Postal Code"
                      errors={errors}
                      touched={touched}
                      placeholder="Postal Code"
                    />
                    <InputFormField
                      name="phone_number"
                      label="Phone Number"
                       placeholder="+1 (555) 555-1234"
                      errors={errors}
                      touched={touched}
                    />
                    <div className=" relative z-[8]">
                      <CustomFormSelect
                        asterisk
                        values={values}
                        errors={errors}
                        touched={touched}
                        placeholder={'Type'}
                        name="enquiry_type"
                        label="Enquiry Type"
                        optionsArr={enquiryTypeOption}
                      />
                    </div>
                    <InputFormField
                      name="website"
                      label="Website"
                      errors={errors}
                      touched={touched}
                      placeholder="Enter Website"
                    />
                  </div>
                </Card>
                <BusinessDocument
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </FormContainer>
              <div
                className="grid grid-cols-1 md:grid-cols-2 col-span-1 md:col-span-2 gap-3 mt-4"
                data-aos="fade-left"
              >
                <MyButton
                  variant="twoTone"
                  type="button"
                  className={'w-full'}
                  size={'md'}
                  text={'Back'}
                  handleClick={() => handleBack(values)}
                  icon={<HiArrowNarrowLeft />}
                />
                <MyButton
                  variant="solid"
                  type="submit"
                  className={'w-full'}
                  size={'md'}
                  text={'Submit'}
                  isLoading={isSubmitting}
                  // icon={<HiUserAdd />}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default BusinessInfo
