
export const ADMIN = 'Admin'
export const INSTITUTION = 'Institution'
export const CUSTOMER = 'Customer'

export const APPROVAL_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}
