import React from 'react'
import { Card, FormItem, Input } from 'components/ui'

const BusinessDocument = ({ errors, touched, setFieldValue }) => {
  return (
    <div>
      <h5 className="my-4">Document</h5>
      <Card bodyClass="pb-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
          <div data-aos="fade-up">
            <FormItem label={'Jurisdiction'}>
              <Input
                name="jurisdiction_document"
                onChange={(e) =>
                  setFieldValue('jurisdiction_document', e.target.files[0])
                }
                placeholder="Choose File"
                type="file"
                accept=".pdf,"
                className={
                  errors?.jurisdiction_document &&
                  touched?.jurisdiction_document &&
                  'input-invalid'
                }
              />
            </FormItem>
          </div>
          <div data-aos="fade-up">
            <FormItem
              label={'Incorporation'}
              asterisk
              invalid={
                errors?.incorporation_content && touched?.incorporation_content
              }
              errorMessage={errors?.incorporation_content}
            >
              <Input
                name="incorporation_content"
                onChange={(e) =>
                  setFieldValue('incorporation_content', e.target.files[0])
                }
                placeholder="Choose File"
                type="file"
                accept=".pdf"
                className={
                  errors?.incorporation_content &&
                  touched?.incorporation_content &&
                  'input-invalid'
                }
              />
            </FormItem>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default BusinessDocument
