import {
  convertBase64,
  getFileDetail,
} from 'utils/helper'
import * as Yup from 'yup'

export const controllingPersonValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  address: Yup.string().required('Address is required'),
  email: Yup.string().required('Email is required').email('Invalid email'),
  country_id: Yup.string().required('Country is required'),
  state_id: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  postal_code: Yup.string().required('Postal Code is required'),
  type_id: Yup.number().required('Type ID is required'),
  content: Yup.mixed().required('Document is required'),
  identification_number: Yup.string().required(
    'Identification number is required'
  ),
})

export const businessValidationSchema = Yup.object().shape({
  legal_name: Yup.string().required('Legal name is required'),
  tax_id: Yup.string().required('Tax ID is required'),
  address: Yup.string().required('Address is required'),
  country_id: Yup.string().required('Country is required'),
  state_id: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  postal_code: Yup.string().required('Postal Code is required'),
  enquiry_type: Yup.string().required('Enquiry type is required'),
  website: Yup.string().url('Invalid website url'),
  incorporation_content: Yup.mixed().required('Document is required'),
  jurisdiction_document: Yup.mixed(),
})

export const controllingPersonInitialValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  dob: null,
  address: '',
  country_id: '',
  state_id: '',
  city: '',
  postal_code: '',
  type_id: '',
  content: '',
  identification_number: '',
  id: '',
}
export const businessInitialValues = {
  legal_name: '',
  tax_id: '',
  address: '',
  country_id: '',
  state_id: '',
  city: '',
  postal_code: '',
  phone_number: '',
  enquiry_type: '',
  website: '',
  jurisdiction_document: '',
  incorporation_content: '',
  id: '',
}

export const parserData = (controlInfo, businessInfo) => {
  const cp_document = controlInfo?.document
    ? JSON.parse(controlInfo?.document || {})
    : null
  const j_document = businessInfo?.jurisdiction_document
    ? JSON.parse(businessInfo?.jurisdiction_document || {})
    : null
  const ic_document = businessInfo?.incorporation_content
    ? JSON.parse(businessInfo?.incorporation_content || {})
    : null
  if (controlInfo && businessInfo) {
    return {
      control_person: {
        id: controlInfo?.id,
        email: controlInfo?.email || '',
        first_name: controlInfo?.first_name || '',
        last_name: controlInfo?.last_name || '',
        phone_number: controlInfo?.phone_number || '',
        dob: controlInfo?.dob || null,
        address: controlInfo?.address || '',
        country_id: controlInfo?.country_id || '',
        state_id: controlInfo?.state_id || '',
        city: controlInfo?.city || '',
        postal_code: controlInfo?.postal_code || '',
        type_id: cp_document?.type_id || '',
        content: cp_document?.content || '',
        identification_number: cp_document?.identification_number || '',
        type_name: cp_document?.type_name || '',
        file_name: cp_document?.file_name||'',
      },
      company_information: {
        id: businessInfo?.id,
        legal_name: businessInfo?.legal_name || '',
        tax_id: businessInfo?.tax_id || '',
        address: businessInfo?.address || '',
        country_id: businessInfo?.country_id || '',
        state_id: businessInfo?.state_id || '',
        city: businessInfo?.city || '',
        postal_code: businessInfo?.postal_code || '',
        phone_number: businessInfo?.phone_number || '',
        enquiry_type: businessInfo?.enquiry_type || '',
        website: businessInfo?.website || '',
        incorporation_content: ic_document?.content || '',
        incorporation_file_name: ic_document?.file_name || '',
        jurisdiction_document: j_document?.content || '',
        jurisdiction_file_name: j_document?.file_name || '',
      },
    }
  }
  return null
}

export const controlPersonBodyParser = async (item) => {
  const res = getFileDetail(item.content)
  const fileString = res ? await convertBase64(res?.file) : item.content
  return {
    id: item.id,
    email: item.email,
    first_name: item.first_name,
    last_name: item.last_name,
    phone_number: item.phone_number,
    dob: item.dob,
    address: item.address,
    country_id: item.country_id,
    state_id: item.state_id,
    city: item.city,
    postal_code: item.postal_code,
    document: {
      file_name: res
        ? `CpDocument${Date.now()}.${res?.fileExtension}`
        : item.file_name,
      type_id: item.type_id,
      type_name: item.type_name,
      identification_number: item.identification_number,
      content: fileString,
    },
  }
}

export const businessBodyParser = async (item) => {
  const ic_file = getFileDetail(item.incorporation_content)
  const jd_File = getFileDetail(item.jurisdiction_document)
  return {
    id: item.id,
    legal_name: item?.legal_name || '',
    tax_id: item?.tax_id || '',
    address: item?.address || '',
    country_id: item?.country_id || '',
    state_id: item?.state_id || '',
    city: item?.city || '',
    postal_code: item?.postal_code || '',
    phone_number: item?.phone_number || '',
    enquiry_type: item?.enquiry_type || '',
    website: item?.website || '',

    jurisdiction_document: {
      type_id: 5,
      type_name: 'Jurisdiction',
      file_name: jd_File
        ? `residence${Date.now()}.${jd_File.fileExtension}`
        : item.jurisdiction_file_name,
      content: jd_File
        ? await convertBase64(jd_File.file)
        : item?.jurisdiction_document,
    },
    incorporation_document: {
      type_id: 6,
      type_name: 'Incorporation',
      file_name: ic_file
        ? `incorporation${Date.now()}.${ic_file.fileExtension}`
        : item.incorporation_file_name,
      content: ic_file
        ? await convertBase64(ic_file.file)
        : item.incorporation_document,
    },
  }
}

export const enquiryTypeOption = [
  {
    label: 'Sales and Production Information',
    value: 'Sales and Production Information',
  },
  {
    label: 'Customer Support',
    value: 'Customer Support',
  },
  {
    label: 'Press enquiries',
    value: 'Press enquiries',
  },
  {
    label: 'Contact the Compliance team',
    value: 'Contact the Compliance team',
  },
  {
    label: 'Law Enforcement Enquiry',
    value: 'Law Enforcement Enquiry',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]
