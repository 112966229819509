import React, {
  memo,
  useMemo,
  lazy,
  Suspense,
  useEffect,
  useState,
} from 'react'
import { Loading } from 'components/shared'
import { useSelector, useDispatch } from 'react-redux'
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import Docusign from 'views/docu-sign'
import { ADMIN, APPROVAL_STATUS } from 'constants/roles.constant'
import { fetchUserById } from 'services/AuthService'
import { setUser } from 'store/auth/userSlice'
import useDarkMode from 'utils/hooks/useDarkMode'

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
  [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
  [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
  const [loading, setLoading] = useState(true)
  const layoutType = useSelector((state) => state.theme.layout.type)
  const state = useSelector((state) => state.auth)
  const { session, user } = state

  const dispatch = useDispatch()
  const [isDark, setIsDark] = useDarkMode()

  useEffect(() => {
    setLoading(false)
  }, [session, user])

  const handleUserDetail = async () => {
    setLoading(true)
    const resp = await fetchUserById(user?.ID)
    setLoading(false)
    if (resp.status) {
      const { user } = resp.data

      localStorage.setItem('user_info', JSON.stringify(user))

      dispatch(setUser(user || null))
    }
  }

  useEffect(() => {
    if (user?.ID) {
      setIsDark('dark')
      handleUserDetail()
    } else {
      setIsDark('light')
    }
  }, [user?.ID])

  const { authenticated } = useAuth()

  useDirection()

  useLocale()

  const AppLayout = useMemo(() => {
    if (authenticated) {
      return layouts[layoutType]
    }
    return lazy(() => import('./AuthLayout'))
  }, [layoutType, authenticated])

  const render = () => {
    if (loading) {
      return (
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      )
    }
    if (!session?.token) {
      return <AppLayout />
    }
    if (user?.ApprovalStatus === APPROVAL_STATUS.APPROVED) {
      return <AppLayout />
    }

    if (user?.Role !== ADMIN && user?.IsDocusigned === false) {
      return <Docusign step={0} />
    }
    if (user?.Role !== ADMIN && user?.IsDocusigned && !user?.IsKycCompleted) {
      return <Docusign step={1} />
    }
    if (
      user?.Role !== ADMIN &&
      (user?.ApprovalStatus === APPROVAL_STATUS.PENDING ||
        user?.ApprovalStatus === APPROVAL_STATUS.REJECTED)
    ) {
      return <Docusign step={2} />
    }

    return <AppLayout />
  }

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      {render()}
    </Suspense>
  )
}

export default memo(Layout)
