import React, { useEffect, useState } from 'react'
import MyButton from 'components/custom-button'
import InputFormField from 'components/my-custom/Input'
import { Card, DatePicker, FormContainer, FormItem } from 'components/ui'
import { Field, Form, Formik } from 'formik'
import {
  controlPersonBodyParser,
  controllingPersonInitialValues,
  controllingPersonValidationSchema,
} from './helper'
import StatesDropDown from 'components/my-custom/state-dropdown'
import ControlPersonDocument from './contro-person-document'
import { kycControlPersonService } from 'services/KycService'
import { openNotification } from 'utils/notificate'
import EntityCountryDropDown from './country-dropdown-entity'

const ControllingPersonInfo = ({ handleNext, control_person }) => {
  const [typeNameVal, setTypeNameVal] = useState(null)

  const handleStepOne = async (values, setSubmitting) => {
    setSubmitting(true)
    const body = await controlPersonBodyParser({
      ...values,
      type_name: typeNameVal,
    })

    const { status, data, error } = await kycControlPersonService(body)
    setSubmitting(false)
    if (status) {
      handleNext({ ...values, type_name: typeNameVal })
      openNotification('success', data?.message || 'Updated successfully')
    }
    if (error) {
      openNotification('danger', error?.message || 'Something went wrong')
    }
  }

  useEffect(() => {
    if (control_person) {
      setTypeNameVal(control_person?.type_name)
    }
  }, [control_person])

  return (
    <div className="pb-8">
      <h5 className="mb-4">Control Person</h5>

      <Formik
        initialValues={control_person || controllingPersonInitialValues}
        validationSchema={controllingPersonValidationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleStepOne(values, setSubmitting)
        }}
      >
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <FormContainer>
                <Card bodyClass="pb-2">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                    <InputFormField
                      asterisk
                      errors={errors}
                      name="first_name"
                      touched={touched}
                      label="First Name"
                      placeholder="First Name"
                    />
                    <InputFormField
                      asterisk
                      errors={errors}
                      name="last_name"
                      touched={touched}
                      label="Last Name"
                      placeholder="Last Name"
                    />
                    <InputFormField
                      asterisk
                      name="email"
                      label="Email"
                      touched={touched}
                      errors={errors}
                      placeholder="Email"
                      disable={true}
                    />
                    <InputFormField
                      errors={errors}
                      touched={touched}
                      name="phone_number"
                      label="Phone Number"
                       placeholder="+1 (555) 555-1234"
                    />

                    <div data-aos='fade-up'>
                    <FormItem label="Date of Birth">
                      <Field name="dob">
                        {({ field, form }) => (
                          <DatePicker
                            field={field}
                            form={form}
                            value={values.dob}
                             inputtable={true}
                              placeholder="YYYY-MM-DD"
                            name='dob'
                            maxDate={new Date()}
                            onChange={(date) => {
                              form.setFieldValue(field.name, date)
                            }}
                          />
                        )}
                      </Field>
                    </FormItem>
                    </div>

                    <InputFormField
                      asterisk
                      name="address"
                      label="Address"
                      errors={errors}
                      touched={touched}
                      placeholder="Address"
                    />
                    <div className="relative z-10">
                      <EntityCountryDropDown
                        values={values}
                        errors={errors}
                        touched={touched}
                        name="country_id"
                      />
                    </div>
                    <div className="relative z-[9]">
                      <StatesDropDown
                      asterisk={true}
                        values={values}
                        errors={errors}
                        touched={touched}
                        name="state_id"
                        countryId={values?.country_id || null}
                      />
                    </div>

                    <InputFormField
                      asterisk
                      name="city"
                      label="City"
                      errors={errors}
                      touched={touched}
                      placeholder="City"
                    />

                    <InputFormField
                      asterisk
                      name="postal_code"
                      label="Postal Code"
                      errors={errors}
                      touched={touched}
                      placeholder="Postal Code"
                    />
                  </div>
                </Card>
                <ControlPersonDocument
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setTypeNameVal={setTypeNameVal}
                />
              </FormContainer>
              <div className="mt-4" data-aos='fade-left'>
                <MyButton
                  variant="solid"
                  type="submit"
                  className={'w-full'}
                  size={'md'}
                  text={'Next'}
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ControllingPersonInfo
