import React from 'react'
import { FormItem } from 'components/ui'
import { Field } from 'formik'
import { PasswordInput } from 'components/shared'

const PasswordInputField = ({
  name,
  label,
  errors,
  touched,
  asterisk,
  placeholder,
  dataAos,
  disable,
}) => {
  return (
    <div data-aos={dataAos||"fade-up"}>
      <FormItem
        label={label}
        asterisk={asterisk || false}
        errorMessage={errors[name]}
        invalid={errors[name] && touched[name]}
      >
        <Field
          name={name}
          autoComplete="off"
          placeholder={placeholder}
          component={PasswordInput}
          readOnly={disable}
          
        />
      </FormItem>
    </div>
  )
}

export default PasswordInputField
