import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { HiOutlineLockClosed } from 'react-icons/hi'

import { Card } from 'components/ui'
import { Container } from 'components/shared'
import MyButton from 'components/custom-button'
import { FormContainer } from 'components/ui/Form'
import { openNotification } from 'utils/notificate'
import { changePassword } from 'services/AuthService'
import PasswordInputField from 'components/my-custom/PasswordInput'

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required('Old password is required'),
  new_password: Yup.string()
    .required('New password is required')
    .min(6, 'Password must be at least 6 characters'),

  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('new_password')], 'Password not match'),
})

const ChangePassword = ({ onClose }) => {
  const handleSubmit = async (values, setSubmitting) => {
    const data = { ...values }
    delete data['confirm_password']
    const { status, error } = await changePassword(data)
    setSubmitting(false)
    if (status) {
      openNotification('success', 'Password updated successfully')
      onClose()
    } else if (error) {
      openNotification('danger', error?.message || 'Something went wrong')
    }
  }

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          old_password: '',
          new_password: '',
          confirm_password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting)
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <div data-aos="zoom-in">
            <Form>
              <FormContainer>
                <h5 className="mb-3">Change Password</h5>
                <Card data-aos="fade-up">
                  <PasswordInputField
                    errors={errors}
                    touched={touched}
                    name={'old_password'}
                    placeholder={'Old Password'}
                    label={'Old Password'}
                    dataAos={'zoom-in'}
                  />
                  <PasswordInputField
                    errors={errors}
                    touched={touched}
                    name={'new_password'}
                    placeholder={'Confirm Password'}
                    label={'New Password'}
                    dataAos={'zoom-in'}
                  />
                  <PasswordInputField
                    errors={errors}
                    touched={touched}
                    name={'confirm_password'}
                    placeholder={'Confirm Password'}
                    label={'Confirm Password'}
                    dataAos={'zoom-in'}
                  />
                </Card>
              </FormContainer>

              <div className="flex items-center justify-end w-full mt-5">
                <MyButton
                  type={'submit'}
                  text={'Change Password'}
                  isLoading={isSubmitting}
                  icon={<HiOutlineLockClosed />}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Container>
  )
}

export default ChangePassword
