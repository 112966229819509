import axios from 'axios'
import { Tag } from 'components/ui'
import appConfig from 'configs/app.config'
import { partnersObj } from 'constants/common.constant'
import { GoDotFill } from 'react-icons/go'
import ApiService from 'services/ApiService'
import { reduxStore } from 'store'
import { onSignOutSuccess } from 'store/auth/sessionSlice'
import { initialState, setUser } from 'store/auth/userSlice'
import { v4 as uuidv4 } from 'uuid'

export const copyToClipBoard = async (copyMe) => {
  try {
    await navigator.clipboard.writeText(copyMe)
    return true
  } catch (err) {
    return false
  }
}

export const getDeviceUUID = () => {
  let deviceID = localStorage.getItem('device-id') || ''
  if (!deviceID) {
    deviceID = uuidv4()
    localStorage.setItem('device-id', deviceID)
  }
  return deviceID
}

export const handleSignOut = () => {
  reduxStore.dispatch(onSignOutSuccess())
  reduxStore.dispatch(setUser(initialState))
  localStorage.removeItem('user_info')
  localStorage.removeItem('login_auth_token')
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}

export const deepClone = (data = []) => {
  return JSON.parse(JSON.stringify(data))
}

export const dateFormate = (date) => {
  if (!date) return '--'
  return new Date(date).toLocaleString()
}

export const getPreviousDayDate = (p_day = 30) => {
  const today = new Date()
  return new Date(new Date().setDate(today.getDate() - Number(p_day)))
}

export const showText = (row, col) => {
  if (col?.method) {
    return col.method(row[col?.accessorKey], row)
  }

  if (!row[col?.accessorKey] && row[col?.accessorKey] !== false) {
    return '--'
  }

  return row[col?.accessorKey]
}

export const scrollToErrorField = (errors = {}, isTop, callBack) => {
  if (Object.keys(errors || {}).length > 0 && isTop) {
    if (
      document.getElementsByName(Object.keys(errors)[0])[0].type === 'hidden'
    ) {
      const parentElement = document.getElementsByName(
        Object.keys(errors)[0]
      )[0].parentElement

      if (parentElement.getElementsByClassName('select__input')[0]) {
        parentElement
          .getElementsByClassName('select__input')[0]
          .scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
      } else {
        parentElement.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
    } else {
      document.getElementsByName(Object.keys(errors)[0])[0].scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
    callBack?.()
  }
}

export const convertBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const convertBase64OnlyString = async (file) => {
  const regex = /data:.*base64,/
  const res = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
  return await res.replace(regex, '')
}

export const getFileDetail = (fileItem) => {
  if (typeof fileItem === 'object') {
    const file = fileItem
    const fileName = fileItem.name
    const fileExtension = fileName.split('.').pop()
    const size = fileItem.size
    const fileSizeInMB = size / (1024 * 1024)
    return {
      size,
      file,
      fileName,
      fileSizeInMB,
      fileExtension,
    }
  }
  return null
}

export async function downloadReports(apiUrl, params, fileName) {
  try {
    const response = await ApiService.fetchData({
      url: apiUrl,
      method: 'get',
      params: params || {},
      responseType: 'blob',
    })
    const url = URL.createObjectURL(new Blob([response.data]))

    // Create an anchor element to trigger the download
    const a = document.createElement('a')
    a.href = url
    a.download = `${fileName}.xlsx` // Set your desired file name here
    a.style.display = 'none'
    document.body.appendChild(a)

    // Trigger a click event to initiate the download
    a.click()

    // Clean up
    URL.revokeObjectURL(url)
    document.body.removeChild(a)

    return 'success'
  } catch (error) {
    console.error('Error fetching data:', error)
    return 'fail'
  }
}

export const formatNumber = (n) => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export const getTruncatedAddress = (str) => {
  if (str?.length > 0) {
    let firstFour = str.substring(0, 4)
    let lastFour = str.substring(str.length - 4)
    return firstFour + '***' + lastFour
  }
}

export const partnersIdsToAryObj = (ids = '') => {
  let tempIds = ids.split(',')
  tempIds = tempIds.reduce((acc, crr) => {
    acc.push(partnersObj[crr])
    return acc
  }, [])

  return tempIds
}

export const renderStatusElement = (status) => {
  switch (status) {
    case 'PENDING':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-yellow-500`} />
          &nbsp;
          <span className="inline-block text-yellow-500">{status}</span>
        </p>
      )
    case 'PendingProcessing':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-yellow-500`} />
          &nbsp;
          <span className="inline-block text-yellow-500">{status}</span>
        </p>
      )
    case 'APPROVED':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-green-500`} />
          &nbsp;
          <span className="inline-block text-green-500">{status}</span>
        </p>
      )
    case 'REJECTED':
      return (
        <>
          <p className="flex items-center">
            <GoDotFill className={`inline-block text-red-500`} />
            &nbsp;
            <span className="inline-block text-red-500">{status}</span>
          </p>
        </>
      )
    case 'POSTED':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-green-500`} />
          &nbsp;
          <span className="inline-block text-green-500">{status}</span>
        </p>
      )
    case 'COMPLETED':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-green-500`} />
          &nbsp;
          <span className="inline-block text-green-500">{status}</span>
        </p>
      )
    case 'PROCESSED':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-blue-500`} />
          &nbsp;
          <span className="inline-block text-blue-500">{status}</span>
        </p>
      )
    case 'Processed':
      return (
        <p className="flex items-center">
          <GoDotFill className={`inline-block text-blue-500`} />
          &nbsp;
          <span className="inline-block text-blue-500">{status}</span>
        </p>
      )

    default:
      return <>{status}</>
  }
}

export const objOfStrToArrOfStr = (obj) => {
  return obj ? obj.slice(1, -1).split(',') : []
}

export const downloadExcel = async (apiUrl, params, fileName) => {
  const token = reduxStore.getState().auth.session.token
  const baseUrl = appConfig.baseUrl
  try {
    // Replace with your API endpoint
    const response = await axios.get(baseUrl + apiUrl, {
      responseType: 'blob', // Set the response type to blob
      params,
      headers: { authorization: token },
    })

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a link element
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.xlsx`) // Set the file name

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically click the link to trigger the download
    link.click()

    // Clean up
    link.remove()
    window.URL.revokeObjectURL(url)
    return { status: true }
  } catch (error) {
    console.error('Error downloading the Excel file:', error)
    return { status: false }
  }
}

export const downloadPDF =  (url, filename) => {
  const f_name = `${filename}.pdf`
  const link = document.createElement('a')
  link.download = f_name
  link.href = url
  link.target = '_blank' // Optional, open the download in a new tab
  link.style.display = 'none' // Ensure the link is not visible
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
