import InputFormField from 'components/my-custom/Input'
import CustomFormSelect from 'components/my-custom/Select'
import { Card, FormItem, Input } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { fetchTypeIDs } from 'services/CommonService'

const ControlPersonDocument = ({
  errors,
  touched,
  values,
  setFieldValue,
  setTypeNameVal,
}) => {
  const [typeIds, setTypeIds] = useState([])
  const [isLoading, setIsLoading] = useState([])

  const handleFetchTypeIds = async () => {
    setIsLoading(true)
    const { status, data } = await fetchTypeIDs('1,2,3,4,8')
    setIsLoading(false)
    if (status) {
      setTypeIds(data.data)
    }
  }

  useEffect(() => {
    handleFetchTypeIds()
  }, [])

  return (
    <div>
      <h5 className="my-4">Document</h5>
      <Card bodyClass="pb-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
          <div className="relative">
            <CustomFormSelect
              asterisk
              optionsArr={typeIds}
              errors={errors}
              touched={touched}
              values={values}
              name={'type_id'}
              label={'Type Id'}
              isLoading={isLoading}
              handleChange={(opt) => setTypeNameVal(opt.label)}
            />
          </div>
          <div data-aos='fade-up'>
          <FormItem
            label={'Upload Document'}
            asterisk
            invalid={errors?.content && touched?.content}
            errorMessage={errors?.content}
          >
            <Input
              name="content"
              onChange={(e) => setFieldValue('content', e.target.files[0])}
              placeholder="Choose File"
              type="file"
              accept=".png, .jpg, .jpeg, .jpe"
              className={errors?.content && touched?.content && 'input-invalid'}
            />
          </FormItem>
          </div>
          <div className='col-span-1 md:col-span-2'>
            <InputFormField
              asterisk
              name="identification_number"
              label="Identification Number"
              errors={errors}
              touched={touched}
              placeholder="Identification Number"
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ControlPersonDocument
