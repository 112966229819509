import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiVerifySignInOTP, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
// import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
// import useQuery from './useQuery'

function useAuth() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const query = useQuery()

  const { token, signedIn } = useSelector((state) => {
    return state.auth.session
  })

  const signIn = async (values, isQrOtp) => {
    const resp = await apiVerifySignInOTP(values, isQrOtp)
    if (resp.status) {
      const { token } = resp.data
      const { user } = resp.data
      // console.log(user)

      localStorage.setItem('login_auth_token', JSON.stringify(token))
      localStorage.setItem('user_info', JSON.stringify(user))

      dispatch(setUser(user || null))
      dispatch(onSignInSuccess(token))

      // let redirectUrl = query.get(REDIRECT_URL_KEY)
      // let navigateTo = ''

      // if (user.Role == 'Customer' && user.ApprovalStatus == 'PENDING') {
      //   navigateTo = '/onboarding'
      // } else if (user.Role == 'Customer') {
      //   navigateTo = appConfig.authenticatedEntryPath
      // } else if (user.Role == 'Admin') {
      //   navigateTo = appConfig.adminEntryPath
      // } else if (user.Role == 'Institution') {
      //   navigateTo = appConfig.customerEntryPath
      // } else navigateTo = redirectUrl

      // navigate(navigateTo)
      return {
        status: 'success',
        message: '',
      }
    } else {
      return {
        status: 'failed',
        message: resp.error?.message,
      }
    }
  }

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values)
      return resp
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors,
      }
    }
  }

  const handleSignOut = async () => {
    dispatch(onSignOutSuccess())
    dispatch(setUser(initialState))
    localStorage.removeItem('user_info')
    localStorage.removeItem('login_auth_token')
    navigate(appConfig.unAuthenticatedEntryPath)
  }

  const signOut = async () => {
    // await apiSignOut()
    await handleSignOut()
    // window.location.reload()
  }

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  }
}

export default useAuth
