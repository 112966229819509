import React from 'react'
import { useSelector } from 'react-redux'

import Header from 'components/template/Header'
import { APP_NAME } from 'constants/app.constant'
import UserDropdown from 'components/template/UserDropdown'
import ModeSwitcher from 'components/template/ThemeConfigurator/ModeSwitcher'
import classNames from 'classnames'

const LOGO_SRC_PATH = '/img/logo/'

const type = 'streamline'

const DocuHeader = () => {
  const HeaderActionsStart = () => {
    const mode = useSelector((state) => state.theme.mode)
    return (
      <>
        <div
          className={classNames('logo')}
          style={
            {
              // ...{ width: 130, padding: 15 },
              // ...style,
            }
          }
        >
          <img
            className={'w-20'}
            src={`${LOGO_SRC_PATH}logo-${mode}-${type}.svg`}
            alt={`${APP_NAME} logo`}
          />
        </div>
      </>
    )
  }

  const HeaderActionsEnd = () => {
    return (
      <>
        {/* <LanguageSelector /> */}
        {/* <Notification /> */}
        <ModeSwitcher />
        {/* <SidePanel /> */}
        <UserDropdown hoverable={false} />
      </>
    )
  }

  return (
    <div>
      <Header
        className="border-b border-gray-200 dark:border-gray-700 fixed top-0"
        headerEnd={<HeaderActionsEnd />}
        headerStart={<HeaderActionsStart />}
      />
    </div>
  )
}

export default DocuHeader
