import { createSlice } from '@reduxjs/toolkit'

const arr = ['undefined', undefined, 'null', null]

let authToken = null
if (
  localStorage.getItem('login_auth_token') &&
  !arr.includes(localStorage.getItem('login_auth_token'))
) {
  authToken = JSON.parse(localStorage.getItem('login_auth_token'))
}

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState: {
    token:  authToken,
    signedIn:  authToken ? true : false,
    rememberMe: false,
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      state.token = action.payload
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.token = ''
    },

    setRememberMe: (state, action) => {
      state.rememberMe = action.payload
    },
  },
})

export const { onSignInSuccess, onSignOutSuccess, setRememberMe } =
  sessionSlice.actions

export default sessionSlice.reducer
