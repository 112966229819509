import toast from 'components/ui/toast'
import Notification from 'components/ui/Notification'

export const openNotification = (
  type = 'success',
  message,
  placement = 'top-end'
) => {
  toast.push(
    <Notification
      title={
        type.charAt(0).toUpperCase() + type.slice(1) === 'Danger'
          ? 'Error'
          : type.charAt(0).toUpperCase() + type.slice(1)
      }
      type={type}
      closable
    >
      {message}
    </Notification>,
    { placement: placement }
  )
}
