import React, { useEffect, useState } from 'react'
import ControllingPersonInfo from './controling-person'
import BusinessInfo from './business-info'

import { parserData } from './helper'

const KycFormStep = ({ kycData }) => {
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState(null)

  const handleNext = (values) => {
    setFormValues((prev) => ({ ...prev, control_person: values }))
    setStep(1)
  }

  const handleBack = (values) => {
    setFormValues((prev) => ({ ...prev, company_information: values }))
    setStep(0)
  }

  useEffect(() => {
    if (kycData) {
      const res = parserData(
        kycData.control_person || null,
        kycData?.company_information || null
      )
      setFormValues(res)
    }
  }, [kycData])

  return (
    <>
      {step == 0 && (
        <ControllingPersonInfo
          handleNext={handleNext}
          control_person={formValues?.control_person || null}
        />
      )}
      {step == 1 && (
        <BusinessInfo
          handleBack={handleBack}
          company_information={formValues?.company_information || null}
        />
      )}
    </>
  )
}

export default KycFormStep
