import React from 'react'

const AdminApproval = () => {
  return (
    <div>
        <h4>Waiting for the admin approval</h4>
    </div>
  )
}

export default AdminApproval
