export const partnersObj = {
  // Ibanera, Banking Circle, Bancolombia
  ['1']: { label: 'Ibanera', value: '1' },
  ['2']: { label: 'Banking Circle', value: '2' },
  ['3']: { label: 'Bancolombia', value: '3' },
}

export const adminPartners = [
  { label: 'Ibanera', value: '1' },
  { label: 'Banking Circle', value: '2' },
  { label: 'Bancolombia', value: '3' },
]
