import React from 'react'
import { Field } from 'formik'
import { FormItem, Select } from 'components/ui'

const CustomFormSelect = ({
  name,
  label,
  errors,
  values,
  touched,
  infoText,
  asterisk = false,
  optionsArr,
  placeholder,
  isLoading,
  handleChange,
  disabled = false,
}) => {
  const optVal =
    optionsArr?.find((option) => option.value == values[name]) || null

  return (
    <div data-aos="fade-up">
      <FormItem
        asterisk={asterisk}
        label={label}
        invalid={errors[name] && errors[name] && touched[name]}
        errorMessage={errors[name]}
      >
        <Field name={name}>
          {({ field, form }) => (
            <Select
              className="text-left"
              field={field}
              placeholder={placeholder || 'Select'}
              form={form}
              isLoading={isLoading}
              options={optionsArr}
              isDisabled={disabled}
              value={optVal}
              onChange={(option) => {
                form.setFieldValue(field.name, option?.value)
                handleChange?.(option, field.name)
              }}
            />
          )}
        </Field>
        {infoText && (
          <span className="mt-1 block  text-xs" style={{ fontStyle: 'italic' }}>
            {infoText}
          </span>
        )}
      </FormItem>
    </div>
  )
}

export default CustomFormSelect
