import React, { useEffect, useState } from 'react'
import { fetchCountryList } from 'services/CommonService'
import CustomFormSelect from './Select'
import { useSelector } from 'react-redux'
import { INSTITUTION } from 'constants/roles.constant'

const CountryDropDown = ({
  name,
  label,
  values,
  errors,
  touched,
  disabled,
  infoText,
  handleChange,
  setCountryList,

}) => {

  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loginUser = useSelector((s) => s.auth.user)

  const handleFetchCountry = async () => {
    setIsLoading(true)
    const url =
      loginUser?.Role === INSTITUTION
        ? '/dropdowns/countries-by-customer'
        : '/dropdowns/countries'
    const { status, data } = await fetchCountryList(url)
    setIsLoading(false)

    if (status) {
      setList(data.data || [])
      setCountryList && setCountryList(data.data || [])
    }
  }

  useEffect(() => {
    handleFetchCountry()
  }, [])

  return (
    <div>
      <CustomFormSelect
        asterisk
        values={values}
        errors={errors}
        touched={touched}
        placeholder={'Select'}
        name={name}
        label={label||"Country"}
        optionsArr={list}
        isLoading={isLoading}
        handleChange={handleChange && handleChange}
        disabled={disabled}
        infoText={infoText}
      />
    </div>
  )
}

export default CountryDropDown
